import {getOutlet, useOutlet} from 'reconnect.js';
import {useCallback, useEffect, useState} from 'react';
import {aggregateConcessions, formatSessions} from '../Utils/Order';

export const useOrder = (id) => {
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrderById = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        const resp = await actions.fetchOrderById(id);
        setOrder({
          ...resp,
          isFilmOrder: !!resp.vista_order.Sessions,
          sessions: formatSessions(
            resp.vista_order.Sessions,
            resp.films_info?.[0],
          ),
          aggregateConcessions: aggregateConcessions(
            resp.vista_order.Concessions,
          ),
        });
      } catch (e) {
        console.log('debug', e);
        setOrder(null);
      } finally {
        setIsLoading(false);
      }
    },
    [actions],
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    fetchOrderById(id).then(() => {});
  }, [fetchOrderById, id, user]);

  return {
    isLoading,
    order,
    fetchOrderById,
  };
};
