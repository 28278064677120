import React from 'react';
import styled from 'styled-components';
import {RectButton} from '../../Components/RectButton';
import {Card, FlexRowCenterStart, Text22} from '../../widget';
import {SectionHeader} from '../Landing/SectionHeader';
import {Color} from '../../constants';
import * as L from '../../Utils/Lang';
import {FilmIntro} from '../../Components/FilmIntro';
import {FilmContextSection} from '../../Components/FilmContextSection';
import {FilmProperty} from '../../Components/FilmProperty';
import {
  AccessTime,
  CalendarToday,
  LocalMovies,
  LocationOn,
} from '@styled-icons/material';
import moment from 'moment';
import {useOrder} from '../../Hooks/useOrder';
import {getFormat} from '../../Utils/Price';
import {navigate} from 'gatsby-link';
import {getOrderSeatsInfo} from '../../Utils/Ticket';

export const TicketDetail = (props) => {
  const {orderId} = props;
  const {order, isLoading: isOrderLoading} = useOrder(orderId);

  if (isOrderLoading) {
    return null;
  }

  const film = order.films_info[0];

  return (
    <StyledTicketDetail>
      <Card>
        <SectionHeader title="交易明細" caption="recipe" />
        <div className="dashed-sep" />
        <FilmIntro>
          <img
            src={film.poster}
            alt="film"
            onError={(e) => {
              e.target.src =
                'https://fakeimg.pl/282x400/?text=404&font=lobster';
            }}
          />
          <FilmContextSection
            film={{
              caption: {
                title: film.titleAlt,
                subtitle: film.title,
              },
              rating: film.rating,
            }}>
            <div className="row">
              <FilmProperty
                style={{marginRight: 5}}
                value={`${film.runTime}分鐘`}>
                <AccessTime size={12} />
              </FilmProperty>
              <FilmProperty value={film.filmPlayType}>
                <LocalMovies size={12} />
              </FilmProperty>
            </div>
            <FilmProperty value={film.cinemaName}>
              <LocationOn size={12} />
            </FilmProperty>
            <FilmProperty
              value={moment(order.sessions[0].showDateTime).format(
                'YYYY/MM/DD HH:MM',
              )}>
              <CalendarToday size={12} />
            </FilmProperty>

            <FilmProperty
              value={`第 ${
                order.films_info[0].screenNumber
              } 廳 ${getOrderSeatsInfo(order.sessions[0].tickets)}`}>
              <LocationOn size={12} />
            </FilmProperty>
          </FilmContextSection>
        </FilmIntro>
        <div className="dashed-sep" />

        {order.sessions[0].aggregateTickets.map((t, i) => (
          <div key={i} className="ticket-property">
            <div className="name">
              {L.d({zh: t.altDescription, en: t.description})}
            </div>
            <div className="quantity">x{t.quantity}</div>
            <div className="price">{getFormat(t.quantity * t.priceCents)}</div>
          </div>
        ))}

        {order.aggregateConcessions.map((c, i) => {
          const targetConcession = order.concessions_info.find(
            (ci) => ci.id === c.id,
          );
          return (
            <div key={i} className="ticket-property">
              <div className="name">{targetConcession.title}</div>
              <div className="quantity">x{c.quantity}</div>
              <div className="price">
                {getFormat(c.quantity * c.finalPriceCents)}
              </div>
            </div>
          );
        })}

        <div className="ticket-property">
          <div className="name">手續費</div>
          <div className="price">
            {getFormat(order.vista_order.BookingFeeValueCents)}
          </div>
        </div>
        <div className="dashed-sep" />
        <div className="ticket-property">
          <div className="name">總計</div>
          <Text22
            style={{
              color: Color.orange,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'right',
            }}>
            {getFormat(order.vista_order.TotalValueCents)}
          </Text22>
        </div>
      </Card>
      <div style={{flex: 1}} />

      {order.is_paid_booking && (
        <RectButton
          style={{
            margin: '10px 0',
            width: '100%',
          }}
          onClick={async () => {
            await navigate(`/checkout?id=${orderId}`);
          }}>
          信用卡付款
        </RectButton>
      )}
      {!order.is_paid_booking && (
        <RectButton
          style={{
            margin: '10px 0',
            width: '100%',
          }}
          onClick={async () => {
            await navigate(`/wallet`);
          }}>
          完成訂單
        </RectButton>
      )}
    </StyledTicketDetail>
  );
};

const StyledTicketDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & .dashed-sep {
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed ${Color.black_50};
    margin: 10px 0;
  }
  & .ticket-property {
    width: 100%;
    ${FlexRowCenterStart};
    text-align: left;
    & > .name {
      min-width: 200px;
    }
    & > .price {
      text-align: right;
      width: 100%;
    }
  }
`;
