import React from 'react';
import styled from 'styled-components';
import {SectionHeader} from '../Landing/SectionHeader';
import {RectButton} from '../../Components/RectButton';
import {
  Color,
  SEAT_STATUS_TYPE,
  SEAT_STATUS_TYPE_DISPLAY,
} from '../../constants';
import {
  FlexColumnCenterCenter,
  FlexRowCenterCenter,
  FlexRowCenterStart,
  FlexRowStartStart,
  Text12,
} from '../../widget';
import Monitor from '../../images/temp/monitor.png';
import {totalTicketQuantity} from '../../Utils/Seats';
import {getAvailableSelectSeatsCount} from '../../Utils/Ticket';

export const ChooseSeat = (props) => {
  const {
    onClick,
    setValues,
    values,
    filmAvailableSeats,
    onSelectSeat,
    onFetchingSeats,
    isFetching,
    filmAvailableTicketList,
  } = props;
  const time = 7;

  return (
    <StyledChooseSeat filmAvailableSeats={filmAvailableSeats}>
      <SectionHeader
        style={{marginTop: 20, marginBottom: 15}}
        title="選取座位"
        caption="pick your seat"
      />

      <div className="seat-status-container">
        {SEAT_STATUS_TYPE_DISPLAY.map((t, i) => (
          <div key={i} className="seat-status">
            <div
              style={{
                borderRadius: 7.5,
                width: 15,
                height: 15,
                backgroundColor: t.color,
                marginRight: 4,
              }}
            />
            <Text12>{t.title}</Text12>
          </div>
        ))}
      </div>

      <div className="horizontal-scroll-container">
        <div
          className="screen-container"
          style={{
            minWidth: 100 * time + 60,
            minHeight: (100 - filmAvailableSeats.boundaryTop) * time,
            position: 'relative',
            margin: '0 35px',
          }}>
          {filmAvailableSeats.areas.map((area, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                width: area.width * time,
                height: area.height * time,
                top: (area.top - filmAvailableSeats.boundaryTop) * time,
                left: area.Left * time,
              }}>
              {area.seats.map((seat, i) => {
                const isSelected = !!values.seats.find(
                  (s) =>
                    s.position.columnIndex === seat.position.columnIndex &&
                    s.position.rowIndex === seat.position.rowIndex &&
                    s.position.areaNumber === seat.position.areaNumber,
                );
                const seatSize = 20;
                const gutterH =
                  (area.width * time - seatSize * area.columnCount) /
                  (area.columnCount + 1);
                const gutterV =
                  (area.height * time - seatSize * area.RowCount) /
                  (area.RowCount + 1);
                const left =
                  (seat.position.columnIndex + 1) * gutterH +
                  seat.position.columnIndex * seatSize;
                const top =
                  (seat.position.rowIndex + 1) * gutterV +
                  seat.position.rowIndex * seatSize;
                return (
                  <div key={i}>
                    {seat.isFirstSeat && (
                      <div
                        style={{
                          position: 'absolute',
                          width: seatSize,
                          height: seatSize,
                          top: top,
                          left: -30,
                          backgroundColor: Color.orange,
                          color: Color.white,
                          fontWeight: 'bold',
                          borderRadius: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {seat.physicalRowName}
                      </div>
                    )}
                    <Seat
                      style={{
                        width: seatSize,
                        height: seatSize,
                        top: top,
                        left: left,
                      }}
                      onClick={() => {
                        onSelectSeat(seat);
                      }}
                      status={isSelected ? SEAT_STATUS_TYPE.own : seat.status}
                    />

                    {seat.isFirstSeat && (
                      <div
                        style={{
                          position: 'absolute',
                          width: seatSize,
                          height: seatSize,
                          top: top,
                          right: -30,
                          backgroundColor: Color.orange,
                          color: Color.white,
                          fontWeight: 'bold',
                          borderRadius: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {seat.physicalRowName}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>

      <div className="monitor">
        <Text12 style={{color: Color.black_50}}>螢幕</Text12>
        <img src={Monitor} alt="monitor" />
      </div>

      <div style={{flex: 1}} />

      <div className="hint">
        還有{' '}
        <div style={{color: Color.orange}}>
          {getAvailableSelectSeatsCount(
            filmAvailableTicketList,
            values.tickets,
          ) - values.seats.length}
        </div>{' '}
        張未確認座位
      </div>

      <RectButton
        isLoading={isFetching}
        style={{
          margin: '10px 0',
          width: '100%',
        }}
        onClick={() => {
          onFetchingSeats();
        }}>
        重新整理
      </RectButton>

      <RectButton
        style={{
          margin: '10px 0',
          width: '100%',
        }}
        onClick={() => {
          setValues((prev) => ({...prev, seats: []}));
        }}>
        重設座位
      </RectButton>

      <RectButton
        style={{
          margin: '10px 0',
          width: '100%',
        }}
        onClick={onClick}>
        下一步
      </RectButton>
    </StyledChooseSeat>
  );
};

const StyledChooseSeat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > .seat-status-container {
    ${FlexRowCenterStart};
    margin-bottom: 20px;
    & > .seat-status {
      ${FlexRowCenterStart};
      margin-right: 15px;
    }
  }
  & > .horizontal-scroll-container {
    ::-webkit-scrollbar {
      display: none;
    }
    max-width: 100%;
    overflow-x: auto;
    align-self: center;
  }
  & > .monitor {
    ${FlexColumnCenterCenter};
    & > img {
      width: 90%;
      height: calc(90% * 18 / 307);
      transform: rotate(180deg);
    }
  }
  & > .hint {
    ${FlexRowCenterCenter};
    margin: 10px 0;
  }
`;

const Seat = (props) => {
  const {style = {}, onClick, status} = props;
  return <StyledSeat style={style} status={status} onClick={onClick} />;
};

const StyledSeat = styled.div`
  position: absolute;
  background-color: ${({status}) =>
    SEAT_STATUS_TYPE_DISPLAY.find((s) => s.key === status).color};
  border-radius: 5px;
  &:active {
    opacity: 0.3;
  }
`;
