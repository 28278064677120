import React, {useState, Fragment} from 'react';
import styled from 'styled-components';
import {SectionHeader} from '../Landing/SectionHeader';
import {BlockSelector} from '../../Components/BlockSelector';
import {Color} from '../../constants';
import * as L from '../../Utils/Lang';
import {RectButton} from '../../Components/RectButton';
import {Text14} from '../../widget';
import {Counter} from './Counter';
import {TicketBlock} from './TicketBlock';
import {totalTicketQuantity} from '../../Utils/Seats';
import Ticket from '../../images/temp/ticket-film.jpg';
import {getFormat} from '../../Utils/Price';
import {
  getTicketsByFilterWithTicketGroupType,
  getTicketsByPaidType,
  getTicketsGroupsConfig,
  TICKET_GROUP_TYPES,
} from '../../Utils/Ticket';
import {AlertModalContent} from '../../Components/AlertModalContent';
import {ConfirmModalContent} from '../../Components/ConfirmModalContent';
import {useOutlet} from 'reconnect.js';
import constants from '../../../data.json';

function getQuantity(tickets, t) {
  return (
    tickets.reduce((acc, ticket) => {
      if (ticket.ticketTypeCode === t.ticketTypeCode) {
        acc += ticket.quantity;
      }
      return acc;
    }, 0) || 0
  );
}

function AddGroupTicketModalContent(props) {
  const {modalRef, filmAvailableTicketList, values, setValues} = props;
  const [actions] = useOutlet('actions');
  const [isFetching, setIsFetching] = useState(false);
  const [barcode, setBarcode] = useState('');
  return (
    <ConfirmModalContent
      isLoading={isFetching}
      title="請輸入團體票券條碼"
      subtitle="輸入完整團體票券條碼驗證即可加入票券"
      onCancel={() => {
        modalRef.current.close();
      }}
      onConfirm={async () => {
        try {
          setIsFetching(true);
          const payload = {
            Barcode: barcode,
            SessionId: values.sessionId,
            CinemaId: '1001',
          };
          const resp = await actions.validateGroupTicket(payload);
          const {ticketTypes, numberOfRedemptionsRemaining} = resp;
          const targetTicketTypeCode = ticketTypes[0].ticketTypeCode;

          const prevTickets = values.tickets;
          if (totalTicketQuantity(prevTickets) >= 6) {
            alert('超過單次購買票數上限，請分批購買');
            return;
          }

          const targetTicket = prevTickets.find(
            (t) => t.OptionalBarcode === barcode,
          );

          if (
            targetTicket &&
            targetTicket.quantity >= numberOfRedemptionsRemaining
          ) {
            modalRef.current.open();
            modalRef.current.set({
              elem: (
                <AlertModalContent
                  title="提醒"
                  subtitle="此團體票券已經使用過，或已經加入此次購買中"
                  onConfirm={() => {
                    modalRef.current.close();
                  }}
                />
              ),
            });
            return;
          }

          const appendedTicket = [
            ...prevTickets,
            {
              ticketTypeCode: targetTicketTypeCode,
              quantity: 1,
              priceInCents: filmAvailableTicketList.find(
                (t) => t.ticketTypeCode === targetTicketTypeCode,
              ).priceInCents,
              OptionalBarcode: barcode,
            },
          ];

          setBarcode('');
          setValues((prev) => ({
            ...prev,
            tickets: appendedTicket,
            seats: [],
          }));
          modalRef.current.close();
        } catch (e) {
          modalRef.current.open();
          modalRef.current.set({
            elem: (
              <AlertModalContent
                title="此票券條碼不合法"
                subtitle="請輸入合法的票券條碼"
                onConfirm={() => {
                  modalRef.current.close();
                }}
              />
            ),
          });
        } finally {
          setIsFetching(false);
        }
      }}>
      <Input
        type="text"
        style={{margin: '10px 0', width: '100%'}}
        placeholder="團體票券條碼"
        value={barcode}
        onChange={(e) => {
          const {value} = e.target;
          setBarcode(value);
        }}
      />
    </ConfirmModalContent>
  );
}

export const ChooseTicketQuantity = (props) => {
  const {
    onClick,
    setValues,
    values,
    filmAvailableTicketList,
    activeTicketGroupType,
    setActiveTicketGroupType,
    modalRef,
  } = props;
  const [actions] = useOutlet('actions');

  return (
    <StyledChooseTicketQuantity>
      <SectionHeader
        style={{marginTop: 20, marginBottom: 15}}
        title="選取張數"
        caption="quantity"
      />

      {activeTicketGroupType === TICKET_GROUP_TYPES.group && (
        <RectButton
          style={{
            margin: '10px 0',
            width: '100%',
          }}
          onClick={() => {
            modalRef.current.open();
            modalRef.current.set({
              elem: (
                <AddGroupTicketModalContent
                  modalRef={modalRef}
                  values={values}
                  setValues={setValues}
                  filmAvailableTicketList={filmAvailableTicketList}
                />
              ),
            });
          }}>
          使用團體兌換券
        </RectButton>
      )}

      {getTicketsByPaidType(filmAvailableTicketList, values.isPaidBooking)
        .length > 0 && (
        <BlockSelector
          active={activeTicketGroupType}
          setActive={(value) => {
            setActiveTicketGroupType(value);
          }}
          options={getTicketsGroupsConfig(values.isPaidBooking)}
        />
      )}

      {getTicketsByFilterWithTicketGroupType(
        getTicketsByPaidType(filmAvailableTicketList, values.isPaidBooking),
        activeTicketGroupType,
      ).map((t, i) => (
        <TicketBlock key={i}>
          <div className="top">
            <img
              src={`${constants.ticketImageCdn}${t.ticketTypeCode}`}
              alt="ticket"
            />
            <SectionHeader
              title={getFormat(t.priceInCents)}
              caption={L.d(t.ticketTypeDesc)}
              colors={{title: Color.orange, caption: Color.black_50}}
            />
          </div>

          {t.IsPackageTicket && (
            <Fragment>
              <div
                style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: Color.black_10,
                  margin: '10px 0',
                }}
              />
              <div>
                {t.PackageContent.Concessions.map((c, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      margin: '2px 0',
                      textAlign: 'left',
                    }}>
                    <div style={{minWidth: 150}}>
                      {L.d({zh: c.DescriptionAlt, en: c.Description})}
                    </div>
                    <div>x{c.Quantity}</div>
                  </div>
                ))}
                {t.PackageContent.Tickets.map((pt, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      textAlign: 'left',
                    }}>
                    <div style={{minWidth: 150}}>
                      {L.d({zh: pt.DescriptionAlt, en: pt.Description})}
                    </div>
                    <div>x{pt.Quantity}</div>
                  </div>
                ))}
              </div>
            </Fragment>
          )}

          <div className="sep" />

          <div className="bottom">
            <Text14>數量</Text14>
            <Counter
              onMinus={() => {
                const prevTickets = [...values.tickets];
                const targetTicketIdx = prevTickets.findIndex(
                  (ticket) => ticket.ticketTypeCode === t.ticketTypeCode,
                );
                if (targetTicketIdx < 0) {
                  modalRef.current.open();
                  modalRef.current.set({
                    elem: (
                      <AlertModalContent
                        title="請選擇至少一張票"
                        subtitle="最少選擇一張票，才能選擇座位"
                        onConfirm={() => {
                          modalRef.current.close();
                        }}
                      />
                    ),
                  });
                  return;
                }
                const targetTicket = prevTickets[targetTicketIdx];
                prevTickets[targetTicketIdx] = {
                  ticketTypeCode: targetTicket.ticketTypeCode,
                  quantity: targetTicket.quantity - 1,
                  priceInCents: t.priceInCents,
                };
                const nextTickets = prevTickets.filter((p) => p.quantity > 0);
                setValues((prev) => ({
                  ...prev,
                  tickets: nextTickets,
                  seats: [],
                }));
              }}
              onPlus={() => {
                if (activeTicketGroupType === TICKET_GROUP_TYPES.group) {
                  modalRef.current.open();
                  modalRef.current.set({
                    elem: (
                      <AlertModalContent
                        title="提醒"
                        subtitle="請透過上放加入團體兌換券按鈕新增"
                        onConfirm={() => {
                          modalRef.current.close();
                        }}
                      />
                    ),
                  });
                  return;
                }
                const prevTickets = values.tickets;
                if (totalTicketQuantity(prevTickets) >= 6) {
                  modalRef.current.open();
                  modalRef.current.set({
                    elem: (
                      <AlertModalContent
                        title="超過單次購買票數上限"
                        subtitle="超過單次購買票數上限，請分批購買"
                        onConfirm={() => {
                          modalRef.current.close();
                        }}
                      />
                    ),
                  });
                  return;
                }
                const appendedTicket = [
                  ...prevTickets,
                  {
                    ticketTypeCode: t.ticketTypeCode,
                    quantity: 1,
                    priceInCents: t.priceInCents,
                  },
                ];
                setValues((prev) => ({
                  ...prev,
                  tickets: appendedTicket,
                  seats: [],
                }));
              }}
              quantity={getQuantity(values.tickets, t)}
              style={{marginLeft: 10}}
            />
          </div>
        </TicketBlock>
      ))}

      <div style={{flex: 1}} />
      <RectButton
        style={{
          margin: '10px 0',
          width: '100%',
        }}
        onClick={onClick}>
        下一步
      </RectButton>
    </StyledChooseTicketQuantity>
  );
};

const StyledChooseTicketQuantity = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  border: none;
  background-color: ${Color.black_10};
  border-radius: 10px;
  height: 35px;
  padding: 0 10px;
  outline: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`;
