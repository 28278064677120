import styled from 'styled-components';
import {
  FlexColumnCenterCenter,
  FlexRowCenterBetween,
  FlexRowStartStart,
} from '../../widget';
import {BOOK_STEPS_DISPLAY, Breakpoints, Color} from '../../constants';
import React, {useMemo} from 'react';

export const Stepper = (props) => {
  const {step, style = {}} = props;

  const totalSpacingLength = useMemo(() => {
    return BOOK_STEPS_DISPLAY.length - 1;
  }, []);

  const currentStepIndex = useMemo(() => {
    return BOOK_STEPS_DISPLAY.findIndex((s) => s.key === step);
  }, [step]);

  return (
    <StyledStepper style={style}>
      {BOOK_STEPS_DISPLAY.map((s, i) => {
        const active = currentStepIndex >= i;
        return (
          <div key={i} className="circle-container">
            <div
              style={{backgroundColor: active ? Color.orange : Color.gray}}
              className="circle"
            />
            <div style={{color: active ? Color.orange : Color.gray}}>
              {s.title}
            </div>
          </div>
        );
      })}
      <div className="stepper-line">
        <div
          style={{
            height: '100%',
            backgroundColor: Color.orange,
            flex: currentStepIndex,
          }}
        />
        <div
          style={{
            height: '100%',
            backgroundColor: Color.gray,
            flex: totalSpacingLength - currentStepIndex,
          }}
        />
      </div>
    </StyledStepper>
  );
};

const StyledStepper = styled.div`
  position: relative;
  ${FlexRowCenterBetween};
  margin: 50px 0;
  z-index: 0;
  & > .stepper-line {
    ${FlexRowStartStart};
    position: absolute;
    top: 7px;
    left: 7px;
    height: 3px;
    width: 95%;
    z-index: 0;
  }
  & > .circle-container {
    ${FlexColumnCenterCenter};
    z-index: 1;
    & > .circle {
      width: 15px;
      height: 15px;
      background-color: ${Color.orange};
      border-radius: 50%;
    }
  }

  @media screen and (min-width: ${Breakpoints.sm}px) {
    & > .stepper-line {
      width: 96%;
      top: 10px;
    }
    & > .circle-container {
      & > .circle {
        width: 25px;
        height: 25px;
      }
    }
  }
  @media screen and (min-width: ${Breakpoints.md}px) {
    & > .stepper-line {
      width: 97%;
      top: 10px;
    }
  }
  @media screen and (min-width: ${Breakpoints.lg}px) {
    & > .stepper-line {
      width: 98%;
    }
  }
`;
