import React from 'react';
import styled from 'styled-components';
import {Color} from '../../constants';
import {
  FlexColumnCenterCenter,
  FlexRowCenterBetween,
  Text16,
} from '../../widget';
import {Add, Remove} from '@styled-icons/material';

export const Counter = (props) => {
  const {style = {}, onPlus, onMinus, quantity} = props;
  return (
    <StyledCounter style={style}>
      <div className="minus" onClick={onMinus}>
        <Remove color={Color.orange} size={20} />
      </div>
      <Text16 className="quantity">{quantity}</Text16>
      <div className="plus" onClick={onPlus}>
        <Add color={Color.orange} size={20} />
      </div>
    </StyledCounter>
  );
};
const StyledCounter = styled.div`
  width: 120px;
  height: 40px;
  ${FlexRowCenterBetween};
  background-color: ${Color.white};
  border-radius: 10px;
  & > .quantity {
    ${FlexColumnCenterCenter};
    width: 100%;
    height: 100%;
    color: ${Color.black};
    border: 1px solid ${Color.black_10};
  }
  & > .plus,
  .minus {
    ${FlexColumnCenterCenter};
    height: 100%;
    min-width: 30px;
    border: 1px solid ${Color.black_10};
    &:active {
      opacity: 0.3;
    }
  }
  & > .plus {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
  }
  & > .minus {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
  }
`;
