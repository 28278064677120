import React from 'react';
import {FilmIntro} from '../../Components/FilmIntro';
import {FilmContextSection} from '../../Components/FilmContextSection';
import {FilmProperty} from '../../Components/FilmProperty';
import {AccessTime, CalendarToday, LocationOn} from '@styled-icons/material';
import {RectButton} from '../../Components/RectButton';
import {Color, DAYS_DISPLAY} from '../../constants';
import {
  FlexColumnCenterBetween,
  FlexRowCenterBetween,
  FlexRowCenterStart,
  Text12,
} from '../../widget';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import * as L from '../../Utils/Lang';

export const PreBook = (props) => {
  const {film, onClick, isFetching, values, setValues} = props;
  return (
    <Wrapper>
      <FilmIntro>
        <img
          src={film.image}
          alt="film"
          style={{backgroundColor: Color.orange, objectFit: 'cover'}}
          onError={(e) => {
            e.target.src = 'https://fakeimg.pl/282x400/?text=404&font=lobster';
          }}
        />
        <FilmContextSection film={film}>
          <FilmProperty value={`${film.runTime} 分鐘`}>
            <AccessTime size={12} />
          </FilmProperty>
          <FilmProperty
            value={moment(
              film.sessions.find((s) => s.id === values.sessionId).showtime,
            ).format('YYYY/MM/DD HH:mm')}>
            <CalendarToday size={12} />
          </FilmProperty>
        </FilmContextSection>
      </FilmIntro>

      <RectButton
        style={{
          width: '100%',
          backgroundColor: Color.black_10,
          color: Color.black_50,
          justifyContent: 'flex-start',
          margin: '10px 0',
        }}
        icon={<LocationOn size={20} color={Color.black_50} />}>
        大直 ATT 吸引力影城
      </RectButton>

      <Text12 style={{margin: '10px 0'}}>選擇版本</Text12>
      <div className="horizontal-scroll-container">
        {R.uniqBy((s) => s.filmPlayType, film.sessions).map((s, index) => {
          const active = s.filmPlayType === values.playType;
          return (
            <RectButton
              key={index}
              onClick={() => {
                setValues((prev) => ({
                  ...prev,
                  playType: s.filmPlayType,
                  date: s.showDate,
                  sessionId: s.id,
                }));
              }}
              style={{
                minWidth: 100,
                marginRight: 10,
                backgroundColor: active ? Color.orange : Color.black_10,
                color: active ? Color.white : Color.black,
              }}>
              {s.filmPlayType}
            </RectButton>
          );
        })}
      </div>

      <Text12 style={{margin: '10px 0'}}>選擇日期</Text12>

      <div className="horizontal-scroll-container">
        {R.uniqBy(
          (s) => s.showDate,
          film.sessions.filter((s) => s.filmPlayType === values.playType),
        ).map((s, i) => {
          const today = moment(new Date()).format('MM-DD');
          const showDate = moment(s.showtime).format('MM-DD');
          const showDateLong = moment(s.showtime).format('YYYY-MM-DD');
          const isToday = today === showDate;
          const active = showDateLong === values.date;
          return (
            <RectButton
              key={i}
              onClick={() => {
                setValues((prev) => ({
                  ...prev,
                  date: showDateLong,
                  sessionId: s.id,
                }));
              }}
              style={{
                marginRight: 10,
                backgroundColor: active ? Color.orange : Color.black_10,
                color: active ? Color.white : Color.black,
                width: 50,
                height: 50,
              }}>
              {isToday ? (
                <div>今日</div>
              ) : (
                <div className="date-inner-content">
                  <div className="month">
                    {parseInt(moment(s.showtime).format('MM'), 10)}月
                  </div>
                  <div className="day">{moment(s.showtime).format('DD')}</div>
                  <div className="dayOfWeek">
                    ({L.d(DAYS_DISPLAY[moment(s.showtime).get('day')])})
                  </div>
                </div>
              )}
            </RectButton>
          );
        })}
      </div>

      <Text12 style={{margin: '10px 0'}}>選擇場次</Text12>
      <div className="sessions-container">
        {film.sessions
          .filter(
            (s) =>
              moment(s.showtime).format('YYYY-MM-DD') === values.date &&
              s.filmPlayType === values.playType,
          )
          .map((s, i) => {
            const active = s.id === values.sessionId;
            return (
              <RectButton
                onClick={() => {
                  setValues((prev) => ({...prev, sessionId: s.id}));
                }}
                key={i}
                style={{
                  width: 120,
                  height: 50,
                  marginRight: 10,
                  marginBottom: 10,
                  backgroundColor: active ? Color.orange : Color.black_10,
                  color: active ? Color.white : Color.black,
                }}>
                <div className="session-inner-content">
                  <div className="top">
                    <div className="start-time">
                      {moment(s.showtime).format('HH:mm')}
                    </div>
                    <div>-</div>
                    <div className="end-time">
                      {moment(s.endtime).format('HH:mm')}
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="screen">第 {s.screenNumber} 廳</div>
                    <div className="available-seats">
                      剩 {s.seatsAvailables} 座位
                    </div>
                  </div>
                </div>
              </RectButton>
            );
          })}
        {new Array(8).fill(0).map((s, i) => (
          <div key={i} style={{width: 120, height: 1, marginRight: 10}} />
        ))}
      </div>

      <div style={{flex: 1}} />

      <RectButton
        isLoading={isFetching}
        onClick={onClick}
        style={{
          width: '100%',
          margin: '10px 0',
        }}>
        下一步
      </RectButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & > .horizontal-scroll-container {
    ::-webkit-scrollbar {
      display: none;
    }
    ${FlexRowCenterStart};
    max-width: 100%;
    overflow-x: scroll;
    .date-inner-content {
      ${FlexColumnCenterBetween};
      & > .month,
      .dayOfWeek {
        font-size: 8px;
      }
      & > .day {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  & > .sessions-container {
    max-width: 100%;
    ${FlexRowCenterBetween};
    flex-wrap: wrap;
    .session-inner-content {
      width: 100%;
      ${FlexColumnCenterBetween};
      & > .top {
        width: 100%;
        ${FlexRowCenterBetween};
        & > .start-time {
          font-size: 12px;
        }
        & > .end-time {
          font-size: 10px;
        }
      }
      & > .bottom {
        width: 100%;
        ${FlexRowCenterBetween};
        font-size: 10px;
        font-weight: 300;
      }
    }
  }
`;
