import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import useFilm from '../../Hooks/useFilm';
import qs from 'query-string';
import Spinner from '../../Components/Spinner';
import {PreBook} from './PreBook';
import {Stepper} from './Stepper';
import {
  BOOK_STEPS,
  BOOKING_MODE,
  Color,
  HONEYQ_SCREEN_LIST,
  ORDER_STATUS,
  SEAT_STATUS_CODE_TYPE,
  SUSEE_SCREEN_LIST,
} from '../../constants';
import {ChooseTicketType} from './ChooseTicketType';
import {ChooseTicketQuantity} from './ChooseTicketQuantity';
import {FilmIntro} from '../../Components/FilmIntro';
import {FilmContextSection} from '../../Components/FilmContextSection';
import {FilmProperty} from '../../Components/FilmProperty';
import {
  AccessTime,
  CalendarToday,
  ChevronLeft,
  LocalMovies,
  LocationOn,
} from '@styled-icons/material';
import {useOutlet} from 'reconnect.js';
import {ChooseMeal} from './ChooseMeal';
import {ChooseSeat} from './ChooseSeat';
import * as R from 'ramda';
import {isPreserveSingleSeat, totalTicketQuantity} from '../../Utils/Seats';
import {TicketDetail} from './TicketDetail';
import moment from 'moment';
import useModal from '../../Hooks/useModal';
import {SectionHeader} from '../Landing/SectionHeader';
import {RectButton} from '../../Components/RectButton';
import {navigate} from 'gatsby-link';
import {AlertModalContent} from '../../Components/AlertModalContent';
import {aggregateConcessions, formatSessions} from '../../Utils/Order';
import {ConfirmModalContent} from '../../Components/ConfirmModalContent';
import {
  getAvailableSelectSeatsCount,
  getConcessionsInfo,
  getListByFilterSalesChannels,
  TICKET_GROUP_TYPES,
} from '../../Utils/Ticket';

const Book = (props) => {
  const {location} = props;
  const {id, defaultSessionId = null} = qs.parse(location.search);
  const {film, isLoading} = useFilm({filmId: id});
  const [curBookStep, setCurBookStep] = useState(null);
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');
  const [values, setValues] = useState({
    playType: '',
    date: '',
    sessionId: '',
    tickets: [],
    seats: [],
    meals: [],
    isPaidBooking: true,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [filmAvailableTicketList, setFilmAvailableTicketList] = useState([]);
  const [filmAvailableSeats, setFilmAvailableSeats] = useState(null);
  const {modalRef, modalElem} = useModal();
  const [orderId, setOrderId] = useState('');
  const [activeTicketGroupType, setActiveTicketGroupType] = useState('');

  const onSelectSession = async () => {
    try {
      setIsFetching(true);
      const resp = await actions.fetchFilmAvailableTicketList({
        cinemaId: '1001',
        sessionId: values.sessionId,
        isMemberTicket: false,
      });
      setFilmAvailableTicketList(
        getListByFilterSalesChannels(resp).map((t) => ({
          ...t,
          ticketTypeCode: t.TicketTypeCode,
          areaCategoryCode: t.AreaCategoryCode,
          priceInCents: t.PriceInCents,
          ticketTypeDesc: {
            zh: t.DescriptionAlt,
            en: t.Description,
          },
        })),
      );
      goToStep(BOOK_STEPS.choose_ticket_type);
    } catch (e) {
      console.log('debug', e);
    } finally {
      setIsFetching(false);
    }
  };

  const goToStep = (s) => {
    setCurBookStep(s);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onFetchingSeats = async () => {
    try {
      setIsFetching(true);
      const resp = await actions.fetchFilmAvailableSeats({
        cinemaId: 1001,
        sessionId: values.sessionId,
      });

      setFilmAvailableSeats({
        ...resp,
        boundaryTop: resp.BoundaryTop,
        areas: resp.Areas.map((a) => ({
          ...a,
          columnCount: a.ColumnCount,
          rowCount: a.RowCount,
          top: a.Top,
          left: a.Left,
          height: a.Height,
          width: a.Width,
          seats: a.Rows.reduce((r, c) => {
            const firstSeatOfRow = c.Seats[0];
            return [
              ...r,
              ...c.Seats.map((s) => {
                const isFirstSeat =
                  s.Position.ColumnIndex ===
                    firstSeatOfRow.Position.ColumnIndex &&
                  s.Position.RowIndex === firstSeatOfRow.Position.RowIndex;
                return {
                  ...s,
                  status: SEAT_STATUS_CODE_TYPE[s.Status],
                  position: {
                    ...s.Position,
                    rowIndex: s.Position.RowIndex,
                    columnIndex: s.Position.ColumnIndex,
                    areaNumber: s.Position.AreaNumber,
                  },
                  physicalRowName: c.PhysicalName,
                  physicalColumnName: s.Id,
                  areaCategoryCode: a.AreaCategoryCode,
                  isFirstSeat: isFirstSeat,
                };
              }),
            ];
          }, []),
        })),
      });
    } catch (e) {
      console.log('debug', e);
    } finally {
      setIsFetching(false);
    }
  };

  const validateSeats = ({
    nextSelectedSeats,
    curAllSeats,
    totalTicketQuantity,
  }) => {
    if (
      isPreserveSingleSeat({
        nextSelectedSeats,
        curAllSeats,
        totalTicketQuantity,
      })
    ) {
      throw new Error('剩餘票數會空出單數座位');
    }
  };

  const onSelectSeat = (seat) => {
    const isRedundantSelect = values.seats.some(
      (s) =>
        s.position.columnIndex === seat.position.columnIndex &&
        s.position.rowIndex === seat.position.rowIndex &&
        s.position.areaNumber === seat.position.areaNumber,
    );
    if (isRedundantSelect) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="此座位已經選擇"
            subtitle="請選擇其餘座位，或點擊重設座位，重新選擇"
            onConfirm={() => {
              modalRef.current.close();
            }}
          />
        ),
      });
      return;
    }

    const isOccupied = seat.Status !== 0;
    if (isOccupied) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="此座位已售出"
            subtitle="請選擇其餘座位"
            onConfirm={() => {
              modalRef.current.close();
            }}
          />
        ),
      });
      return;
    }

    const {SeatsInGroup} = seat;

    let nextSeats;
    if (SeatsInGroup) {
      const area = filmAvailableSeats.areas.find(
        (a) => a.Number === seat.position.areaNumber,
      );
      const seatsGroup = SeatsInGroup.map((gs) => {
        return area.seats.find(
          (s) =>
            s.position.columnIndex === gs.ColumnIndex &&
            s.position.rowIndex === gs.RowIndex,
        );
      });
      nextSeats = [...values.seats, ...seatsGroup];
    } else {
      nextSeats = [...values.seats, seat];
    }

    if (
      nextSeats.length >
      getAvailableSelectSeatsCount(filmAvailableTicketList, values.tickets)
    ) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="超出所訂購座位數"
            subtitle="請增加票數，或是分批購買"
            onConfirm={() => {
              modalRef.current.close();
            }}
          />
        ),
      });
      return;
    }

    const isSUSEE = SUSEE_SCREEN_LIST.includes(
      film.sessions.find((s) => s.id === values.sessionId).screenNumber,
    );

    const isHONEYQ = HONEYQ_SCREEN_LIST.includes(
      film.sessions.find((s) => s.id === values.sessionId).screenNumber,
    );

    try {
      if (!(isSUSEE || isHONEYQ)) {
        validateSeats({
          nextSelectedSeats: nextSeats,
          curAllSeats: nextSeats.reduce((acc, cur) => {
            const area = acc.find((a) => a.Number === cur.position.areaNumber);
            const seatIndex = area.seats.findIndex(
              (s) =>
                s.position.columnIndex === cur.position.columnIndex &&
                s.position.rowIndex === cur.position.rowIndex &&
                s.position.areaNumber === seat.position.areaNumber,
            );
            area.seats[seatIndex].isOwn = true;
            return acc;
          }, R.clone(filmAvailableSeats.areas)),
          totalTicketQuantity: getAvailableSelectSeatsCount(
            filmAvailableTicketList,
            values.tickets,
          ),
        });
      }
    } catch (e) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title={e.message}
            subtitle="請勿空下單數位子"
            onConfirm={() => {
              modalRef.current.close();
            }}
          />
        ),
      });
      return;
    }

    setValues((prev) => ({
      ...prev,
      seats: nextSeats,
    }));
  };

  useEffect(() => {
    if (!film) {
      return;
    }
    if (defaultSessionId) {
      const targetSession = film.sessions.find(
        (s) => s.id === defaultSessionId,
      );
      const {filmPlayType, showDate, id: sessionId} = targetSession;
      setValues((prev) => ({
        ...prev,
        playType: filmPlayType,
        date: showDate,
        sessionId: sessionId,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        playType: film.sessions[0].filmPlayType,
        date: film.sessions[0].showDate,
        sessionId: film.sessions[0].id,
      }));
    }
  }, [defaultSessionId, film]);

  useEffect(() => {
    if (values.isPaidBooking) {
      setActiveTicketGroupType(TICKET_GROUP_TYPES.normal);
    } else {
      setActiveTicketGroupType(TICKET_GROUP_TYPES.free);
    }
  }, [values.isPaidBooking]);

  const fetchOrderList = useCallback(async () => {
    try {
      const resp = await actions.fetchOrderList();
      return resp.map((o) => {
        return {
          ...o,
          isFilmOrder: !!o.vista_order.Sessions,
          sessions: formatSessions(o.vista_order.Sessions, o.films_info?.[0]),
          aggregateConcessions: aggregateConcessions(o.vista_order.Concessions),
        };
      });
    } catch (e) {
      console.log('debug', e);
      return [];
    } finally {
    }
  }, [actions]);

  const onBookSeats = async () => {
    try {
      if (!user) {
        await navigate('/login');
        return;
      }

      setIsFetching(true);

      const orderList = await fetchOrderList();
      const withPendingOrder = orderList.find(
        (o) =>
          o.status === ORDER_STATUS.PAYMENT_WAITING ||
          o.status === ORDER_STATUS.PAYMENT_FAIL,
      );
      if (withPendingOrder) {
        modalRef.current.open();
        modalRef.current.set({
          elem: (
            <ConfirmModalContent
              title="尚有訂單未付款"
              subtitle="請先完成前一筆未付款訂單"
              onConfirm={async () => {
                await navigate(`/checkout?id=${withPendingOrder.id}`);
              }}
              onCancel={() => {
                modalRef.current.close();
              }}
            />
          ),
        });
        return;
      }

      const remainSeatsCount =
        getAvailableSelectSeatsCount(filmAvailableTicketList, values.tickets) -
        values.seats.length;

      if (remainSeatsCount > 0) {
        modalRef.current.open();
        modalRef.current.set({
          elem: (
            <AlertModalContent
              title="座位尚未安排完畢"
              subtitle="請將剩餘票數用盡，或是減少訂購票數"
              onConfirm={() => {
                modalRef.current.close();
              }}
            />
          ),
        });
        return;
      }

      const concessionsInfo = getConcessionsInfo(
        filmAvailableTicketList,
        values,
      );

      const payload = {
        CinemaId: 1001,
        SessionId: values.sessionId,
        TicketTypes: values.tickets.map((t) => ({
          TicketTypeCode: t.ticketTypeCode,
          Qty: t.quantity,
          PriceInCents: t.priceInCents,
          OptionalBarcode: t.OptionalBarcode ? t.OptionalBarcode : undefined,
          BookingFeeOverride: null,
        })),
        thirdPartyMemberScheme: {
          memberCard: '',
          memberDateOfBirth: '',
        },
        ReturnDiscountInfo: true,
        ReturnOrder: true,
        SelectedSeats: values.seats.map((s) => ({
          AreaCategoryCode: s.areaCategoryCode,
          AreaNumber: s.position.areaNumber,
          RowIndex: s.position.rowIndex,
          ColumnIndex: s.position.columnIndex,
        })),
        FilmsInfo: [
          {
            poster: film.image,
            title: film.caption.title,
            titleAlt: film.caption.subtitle,
            rating: film.rating,
            runTime: film.runTime,
            cinemaName: film.cinemaName,
            filmPlayType: values.playType,
            screenNumber: film.sessions.find((s) => s.id === values.sessionId)
              .screenNumber,
          },
        ],
        ConcessionsInfo: concessionsInfo,
        IsPaidBooking: values.isPaidBooking,
        BookingMode: values.isPaidBooking
          ? BOOKING_MODE.paid
          : BOOKING_MODE.unpaid,
      };

      const resp = await actions.bookSeats(payload);

      setOrderId(resp.OrderId);

      let mealResp = null;
      if (values.meals.length > 0) {
        mealResp = await actions.createMealOrder({
          CinemaId: '1001',
          order_id: resp.OrderId,
          Concessions: values.meals.map((m) => ({
            ItemId: m.id,
            Quantity: m.quantity,
            RecognitionId: '',
            RecognitionSequenceNumber: 0,
          })),
          ReturnOrder: true,
          SessionId: values.sessionId,
          GiftStoreOrder: false,
          ConcessionsInfo: concessionsInfo,
          BookingMode: values.isPaidBooking
            ? BOOKING_MODE.paid
            : BOOKING_MODE.unpaid,
        });
      }

      if (!values.isPaidBooking) {
        const completeOrderPayload = {
          order_id: resp.OrderId,
          PaymentInfo: {
            CardNumber: '',
            CardType: 'CREDIT',
            PaymentValueCents: !!mealResp
              ? mealResp.Order.TotalValueCents
              : resp.Order.TotalValueCents,
            PaymentTenderCategory: 'CREDIT',
          },
          PerformPayment: false,
          CustomerPhone: '',
          CustomerEmail: '',
          CustomerName: '',
          ReturnPrintStream: false,
          PrintTemplateName: 'APP',
          PrintStreamType: 0,
        };
        await actions.completeOrder(completeOrderPayload);
      }

      goToStep(BOOK_STEPS.ticket_detail);
    } catch (e) {
      console.log('debug', e);
    } finally {
      setIsFetching(false);
    }
  };

  const onTicketAmountSelect = () => {
    if (totalTicketQuantity(values.tickets) <= 0) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="請選擇至少一張票"
            subtitle="最少選擇一張票，才能選擇座位"
            onConfirm={() => {
              modalRef.current.close();
            }}
          />
        ),
      });
      return;
    }

    const isHONEYQ = HONEYQ_SCREEN_LIST.includes(
      film.sessions.find((s) => s.id === values.sessionId).screenNumber,
    );

    if (
      isHONEYQ &&
      getAvailableSelectSeatsCount(filmAvailableTicketList, values.tickets) %
        2 !==
        0
    ) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="情人廳座位需購買偶數票"
            subtitle="您選擇的影廳為情人廳，需成雙成對"
            onConfirm={() => {
              modalRef.current.close();
            }}
          />
        ),
      });
      return;
    }
    goToStep(BOOK_STEPS.choose_meal);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && !film) {
    return (
      <Wrapper style={{alignItems: 'center', justifyContent: 'center'}}>
        <SectionHeader
          style={{marginBottom: 60}}
          title="搜尋電影已下架"
          caption="no films"
          align="center"
        />
        <RectButton
          style={{width: '100%'}}
          onClick={async () => {
            await navigate('/');
          }}>
          返回首頁
        </RectButton>
      </Wrapper>
    );
  }

  const filmIntro = (
    <FilmIntro>
      <img
        src={film.image}
        style={{backgroundColor: Color.orange, objectFit: 'cover'}}
        alt="film"
        onError={(e) => {
          e.target.src = 'https://fakeimg.pl/282x400/?text=404&font=lobster';
        }}
      />
      <FilmContextSection film={film}>
        <div className="row">
          <FilmProperty style={{marginRight: 5}} value={`${film.runTime}分鐘`}>
            <AccessTime size={12} />
          </FilmProperty>
          <FilmProperty value={values.playType}>
            <LocalMovies size={12} />
          </FilmProperty>
        </div>
        <FilmProperty value={film.cinemaName}>
          <LocationOn size={12} />
        </FilmProperty>
        <FilmProperty
          value={moment(
            film.sessions.find((s) => s.id === values.sessionId).showtime,
          ).format('YYYY/MM/DD HH:mm')}>
          <CalendarToday size={12} />
        </FilmProperty>

        <FilmProperty
          value={`第 ${
            film.sessions.find((s) => s.id === values.sessionId).screenNumber
          } 廳 ${values.seats.map((s, index, array) => {
            if (array.length - 1 === index) {
              return `${s.physicalRowName} 排 ${s.physicalColumnName}號`;
            }
            return `${s.physicalRowName} 排 ${s.physicalColumnName}號 `;
          })}`}>
          <LocationOn size={12} />
        </FilmProperty>
      </FilmContextSection>
    </FilmIntro>
  );

  return (
    <Wrapper>
      {!!curBookStep && (
        <Stepper style={{margin: '15px 0'}} step={curBookStep} />
      )}

      {!!curBookStep && (
        <button
          className="back-step-btn"
          onClick={async () => {
            const curStepIndex = Object.keys(BOOK_STEPS).findIndex(
              (s) => s === curBookStep,
            );
            const prevStep =
              Object.values(BOOK_STEPS)[curStepIndex - 1] || null;
            if (!prevStep || curBookStep === BOOK_STEPS.ticket_detail) {
              setCurBookStep(null);
            }
            setCurBookStep(prevStep);
          }}>
          <ChevronLeft />
          上一步
        </button>
      )}

      {!!curBookStep && curBookStep !== BOOK_STEPS.ticket_detail && filmIntro}

      {!curBookStep && (
        <PreBook
          values={values}
          setValues={setValues}
          film={film}
          onClick={onSelectSession}
          isFetching={isFetching}
        />
      )}

      {curBookStep === BOOK_STEPS.choose_ticket_type && (
        <ChooseTicketType
          values={values}
          setValues={setValues}
          onClick={() => {
            // modalRef.current.open();
            // modalRef.current.set({
            //   elem: <CooperateBankCheckModal modalRef={modalRef} />,
            // });
            goToStep(BOOK_STEPS.ticket_amount);
          }}
        />
      )}

      {curBookStep === BOOK_STEPS.ticket_amount && (
        <ChooseTicketQuantity
          values={values}
          setValues={setValues}
          filmAvailableTicketList={filmAvailableTicketList}
          onClick={onTicketAmountSelect}
          activeTicketGroupType={activeTicketGroupType}
          setActiveTicketGroupType={setActiveTicketGroupType}
          modalRef={modalRef}
        />
      )}

      {curBookStep === BOOK_STEPS.choose_meal && (
        <ChooseMeal
          values={values}
          setValues={setValues}
          onClick={async () => {
            await onFetchingSeats();
            goToStep(BOOK_STEPS.choose_seat);
          }}
          isFetching={isFetching}
        />
      )}

      {curBookStep === BOOK_STEPS.choose_seat && (
        <ChooseSeat
          isFetching={isFetching}
          values={values}
          setValues={setValues}
          onSelectSeat={onSelectSeat}
          onFetchingSeats={onFetchingSeats}
          onClick={onBookSeats}
          filmAvailableSeats={filmAvailableSeats}
          filmAvailableTicketList={filmAvailableTicketList}
        />
      )}

      {curBookStep === BOOK_STEPS.ticket_detail && (
        <TicketDetail orderId={orderId} />
      )}
      {modalElem}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));

  & > button.back-step-btn {
    align-self: flex-start;
    position: relative;
    padding: 8px 28px 8px 15px;
    font-size: 12px;
    line-height: 1.57;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
    & > svg {
      position: absolute;
      width: 24px;
      height: 24px;
      fill: ${Color.orange};
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%) translateX(-25px);
    }
  }
`;

export default Book;
