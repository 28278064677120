import {SEAT_STATUS_TYPE} from '../constants';
import * as R from 'ramda';

const getSeat = (seats, {columnIndex, rowIndex}) => {
  return seats.find(
    (s) =>
      s.position.columnIndex === columnIndex &&
      s.position.rowIndex === rowIndex,
  );
};

const isAvailable = (seat) => {
  if (!seat) {
    return false;
  }
  return !seat.isOwn && seat.status === SEAT_STATUS_TYPE.available;
};

const isRightHandFirstSeatAvailable = (curAllSeats, seat) => {
  return isAvailable(
    getSeat(
      curAllSeats.find((a) => a.Number === seat.position.areaNumber).seats,
      {
        columnIndex: seat.position.columnIndex + 1,
        rowIndex: seat.position.rowIndex,
        areaNumber: seat.position.areaNumber,
      },
    ),
  );
};

const isLeftHandFirstSeatAvailable = (curAllSeats, seat) => {
  return isAvailable(
    getSeat(
      curAllSeats.find((a) => a.Number === seat.position.areaNumber).seats,
      {
        columnIndex: seat.position.columnIndex - 1,
        rowIndex: seat.position.rowIndex,
        areaNumber: seat.position.areaNumber,
      },
    ),
  );
};

export const isPreserveSingleSeat = ({
  nextSelectedSeats,
  curAllSeats,
  totalTicketQuantity,
}) => {
  const remainTicketQuantity = totalTicketQuantity - nextSelectedSeats.length;
  const selectedSeats = R.uniqWith(
    (a, b) => a.rowIndex === b.rowIndex && a.areaNumber === b.areaNumber,
    nextSelectedSeats.map((s) => ({
      rowIndex: s.position.rowIndex,
      areaNumber: s.position.areaNumber,
    })),
  );

  const curSelectSeatRowSeatsList = selectedSeats.map((i) =>
    curAllSeats
      .find((a) => a.Number === i.areaNumber)
      .seats.filter((s) => s.position.rowIndex === i.rowIndex),
  );

  const getSpaceQuantity = (rowSeats) =>
    rowSeats.reduce((acc, cur) => {
      if (
        !isRightHandFirstSeatAvailable(curAllSeats, cur) &&
        !isLeftHandFirstSeatAvailable(curAllSeats, cur) &&
        isAvailable(cur)
      ) {
        acc += 1;
      }
      return acc;
    }, 0);

  const totalSpaceQuantity = curSelectSeatRowSeatsList
    .map((seats) => getSpaceQuantity(seats))
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  return totalSpaceQuantity > remainTicketQuantity;
};

export const totalTicketQuantity = (tickets) => {
  return tickets.reduce((acc, cur) => {
    acc += cur.quantity;
    return acc;
  }, 0);
};

export const totalTicketPrice = (tickets) => {
  return tickets.reduce((acc, cur) => {
    acc += cur.priceInCents * cur.quantity;
    return acc;
  }, 0);
};
