import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {SectionHeader} from '../Landing/SectionHeader';
import {BlockSelector} from '../../Components/BlockSelector';
import {Color} from '../../constants';
import {RectButton} from '../../Components/RectButton';
import {Card, FlexColumnStartStart, FlexRowCenterStart} from '../../widget';
import {Counter} from './Counter';
import {useMealComboList} from '../../Hooks/useMealComboList';
import Spinner from '../../Components/Spinner';
import {getFormat} from '../../Utils/Price';
import Config from '../../../data.json';
import * as L from '../../Utils/Lang';

export const ChooseMeal = (props) => {
  const {onClick, setValues, values, isFetching} = props;
  const {mealComboList, isLoading: isMealLoading} = useMealComboList({
    cinemaId: 1001,
  });
  const [activeCombo, setActiveCombo] = useState('');

  useEffect(() => {
    if (isMealLoading) {
      return;
    }
    setActiveCombo(mealComboList[0].name);
  }, [isMealLoading, mealComboList]);

  const onAddMeal = (m) => {
    const targetIndex = values.meals.findIndex((meal) => meal.id === m.id);
    if (targetIndex > -1) {
      const nextMeals = [...values.meals];
      nextMeals[targetIndex].quantity++;
      setValues((prev) => ({
        ...prev,
        meals: nextMeals,
      }));
    } else {
      const nextMeals = [
        ...values.meals,
        {
          id: m.id,
          quantity: 1,
          title: m.title,
          subtitle: m.subtitle,
          imgUrl: m.imgUrl,
        },
      ];
      setValues((prev) => ({
        ...prev,
        meals: nextMeals,
      }));
    }
  };

  const onRemoveMeal = (m) => {
    const targetIndex = values.meals.findIndex((meal) => meal.id === m.id);
    const curQuantity =
      values.meals.find((meal) => meal.id === m.id)?.quantity || 0;
    if (curQuantity - 1 < 0) {
      return;
    }
    if (targetIndex > -1) {
      const nextMeals = [...values.meals];
      nextMeals[targetIndex].quantity--;
      setValues((prev) => ({
        ...prev,
        meals: nextMeals.filter((m) => m.quantity > 0),
      }));
    }
  };

  if (isMealLoading) {
    return <Spinner />;
  }

  return (
    <StyledChooseMeal>
      <SectionHeader
        style={{marginTop: 20, marginBottom: 15}}
        title="加購餐點"
        caption="food"
      />

      <BlockSelector
        active={activeCombo}
        setActive={setActiveCombo}
        options={mealComboList.map((c) => ({
          name: c.name,
          value: c.name,
        }))}
      />

      {mealComboList
        .filter((c) => c.name === activeCombo)
        .map((c, index) => (
          <div key={index}>
            <div>
              {c.concessionItems.map((m, i) => {
                const quantity =
                  values.meals.find((meal) => meal.id === m.id)?.quantity || 0;
                return (
                  <MealItem key={i}>
                    <div className="top">
                      <img
                        onError={(e) => {
                          e.target.src = `https://fakeimg.pl/90/EE6D22/?text= &font=lobster`;
                        }}
                        src={`${Config.mealImageCdn}${m.id}`}
                        alt="meal"
                      />
                      <div className="context">
                        <div className="title">{m.title}</div>
                        <div className="price">{getFormat(m.priceInCents)}</div>
                        <Counter
                          quantity={quantity}
                          onPlus={() => {
                            onAddMeal(m);
                          }}
                          onMinus={() => {
                            onRemoveMeal(m);
                          }}
                        />
                      </div>
                    </div>
                    {m.PackageChildItems.length > 0 && (
                      <div style={{width: '100%'}}>
                        <div
                          style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: Color.black_10,
                            margin: '10px 0',
                          }}
                        />
                        <div>
                          {m.PackageChildItems.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '2px 0',
                                textAlign: 'left',
                              }}>
                              <div style={{minWidth: 150}}>
                                {L.d({
                                  zh: item.DescriptionAlt,
                                  en: item.Description,
                                })}
                              </div>
                              <div>x{item.Quantity}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </MealItem>
                );
              })}
            </div>
          </div>
        ))}

      <div style={{flex: 1}} />
      <RectButton
        isLoading={isFetching}
        style={{
          margin: '10px 0',
          width: '100%',
        }}
        onClick={onClick}>
        下一步
      </RectButton>
    </StyledChooseMeal>
  );
};

const StyledChooseMeal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MealItem = styled(Card)`
  width: 100%;
  background-color: ${Color.black_10};
  box-shadow: none;
  border: none;
  margin-bottom: 10px;
  min-height: 120px;
  ${FlexColumnStartStart};
  & > .top {
    ${FlexRowCenterStart};
    & > img {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      margin-right: 16px;
      object-fit: cover;
    }
    & > .context {
      ${FlexColumnStartStart};
      & > .tilte,
      .price {
        font-size: 14px;
      }
      & > .price {
        color: ${Color.orange};
      }
    }
  }
`;
