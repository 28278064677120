import styled from 'styled-components';
import {Card, FlexRowCenterEnd, FlexRowCenterStart} from '../../widget';
import {Color} from '../../constants';

export const TicketBlock = styled(Card)`
  width: 100%;
  background-color: ${Color.black_10};
  box-shadow: none;
  border: none;
  margin-bottom: 10px;
  & > .top {
    ${FlexRowCenterStart};
    & > img {
      width: 109px;
      height: 64px;
      border-radius: 10px;
      margin-right: 16px;
      object-fit: cover;
      background-color: ${Color.orange};
    }
  }
  & > .bottom {
    width: 100%;
    ${FlexRowCenterEnd};
  }
  & > .sep {
    height: 1px;
    background-color: ${Color.black_10};
    width: 100%;
    margin: 10px 0;
  }
`;
