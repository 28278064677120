import styled from 'styled-components';
import React from 'react';
import {SectionHeader} from '../Landing/SectionHeader';
import {RectButton} from '../../Components/RectButton';
import {Color, FILM_TICKET_GROUP_TYPE_DISPLAY} from '../../constants';
import * as L from '../../Utils/Lang';

export const ChooseTicketType = (props) => {
  const {values, setValues, onClick} = props;
  return (
    <StyledChooseTicketType>
      <SectionHeader
        style={{marginTop: 20, marginBottom: 15}}
        title="選取票種"
        caption="select"
      />
      <RectButton
        style={{
          margin: '10px 0',
          width: '100%',
          backgroundColor: values.isPaidBooking ? Color.orange : Color.black_10,
          color: values.isPaidBooking ? Color.white : Color.black,
        }}
        onClick={() => {
          setValues((prev) => ({
            ...prev,
            isPaidBooking: true,
            tickets: [],
          }));
        }}>
        一般票種 / 套票 / 銀行優惠
      </RectButton>

      <RectButton
        style={{
          margin: '10px 0',
          width: '100%',
          backgroundColor: !values.isPaidBooking
            ? Color.orange
            : Color.black_10,
          color: !values.isPaidBooking ? Color.white : Color.black,
        }}
        onClick={() => {
          setValues((prev) => ({
            ...prev,
            isPaidBooking: false,
            tickets: [],
          }));
        }}>
        團體兌換券 / 免費兌換券 / 愛心票 / 敬老票
      </RectButton>

      <div style={{flex: 1}} />

      <RectButton
        style={{
          margin: '10px 0',
          width: '100%',
        }}
        onClick={onClick}>
        下一步
      </RectButton>
    </StyledChooseTicketType>
  );
};

const StyledChooseTicketType = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
